<div class="new-header" cdkOverlayOrigin>
  <img class="logo" (click)="onLogoClick()" src="assets/images/friend/application/lt-logo.svg" lazy="loading" />

  <div class="btn-container">
    <customize-button type="text" class="onact96" size="small" (click)="login()">
      {{ 'login_button' | translate }}
    </customize-button>
    <customize-button type="primary" class="onact96" size="small" (click)="register()">
      {{ 'register_button' | translate }}
    </customize-button>
  </div>
</div>
<div
  class="page-container"
  style="
--application-bg: url({{ appService.tenantConfig.config.affiliate_banner }});
--h5-application-bg: url({{ appService.tenantConfig.config.affiliate_banner_h5 }});"
>
  <div class="aff-locale">
    <div class="main-container header-background">
      <div class="container unity-padding-web unity-padding-h5-16 header-container">
        <div class="left-content">
          <p class="text-one">{{ 'affiliate_banner_title' | translate }}</p>
          <p class="text-two">
            {{ 'affiliate_banner_midtitle' | translate }}
          </p>
          <p class="text-three">
            {{ 'affiliate_banner_stitle' | translate }}
          </p>
          <a
            [href]="affiliateRegisterLink"
            target="_blank"
            rel="noopener noreferrer"
            class="top-btn mutual-opacity no-underline"
            >{{ 'apply_imm' | translate }}
          </a>
          <div class="social">
            <a
              class="social-bar no-underline"
              [href]="appService.tenantConfig.config.affiliate_socal_te_link"
              target="_blank"
              rel="noopener noreferrer"
              style="--bar-bg: url('assets/images/friend/application/social-bar.svg')"
            >
              <img class="social-icon" src="assets/images/friend/application/telegram.svg" lazy="loading" />
              {{ 'affiliate_socal_te' | translate }}:
              <p>{{ 'affiliate_socal_te_link' | translate }}</p>
            </a>
            <a
              class="social-bar no-underline"
              [href]="appService.tenantConfig.config.affiliate_socal_sk_link"
              target="_blank"
              rel="noopener noreferrer"
              style="--bar-bg: url('assets/images/friend/application/social-bar.svg')"
            >
              <img class="social-icon" src="assets/images/friend/application/skype.svg" lazy="loading" />
              {{ 'affiliate_socal_sk' | translate }}:
              <p>{{ 'affiliate_socal_sk_link' | translate }}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <div class="container unity-padding-web unity-padding-h5-16 content-container">
        <div class="how-join">
          <div class="left-side">
            <p class="text-one">
              {{ 'affiliate_join_how' | translate }}
            </p>
            <p class="text-two">
              {{ 'affiliate_join_do' | translate }}
            </p>
            <a
              [href]="affiliateRegisterLink"
              target="_blank"
              rel="noopener noreferrer"
              class="btn mutual-opacity no-underline"
            >
              {{ 'apply_imm' | translate }}
            </a>
          </div>
          <div class="right-side">
            <img class="doubao" src="assets/images/friend/application/douhao.svg" lazy="loading" />
            <p class="text-one">
              {{ 'affiliate_join_content1' | translate }}
            </p>
            <p class="text-two" [innerHtml]="'affiliate_join_content2' | translate | toHtmlElement"></p>
          </div>
        </div>

        <div class="how-share">
          <p class="text-one">{{ 'affiliate_how_to_promote' | translate }}</p>
          <p class="text-four">
            {{ 'affiliate_how_to_do' | translate }}
          </p>
          <img class="bolang" src="assets/images/friend/application/bolang.svg" lazy="loading" />
          <img
            class="credit-card"
            [src]="
              theme
                ? 'assets/images/friend/application/credit-card.png'
                : 'assets/images/friend/application/dark-credit-card.png'
            "
            lazy="loading"
          />
          <div class="step">
            <div class="step-one">
              <div class="top">
                <img src="assets/images/friend/application/emplify.svg" lazy="loading" />
                <img class="down" src="assets/images/friend/application/arrow-down.svg" lazy="loading" />
              </div>
              <div class="bottom">
                <p class="text-one">{{ 'affiliate_step1_title' | translate }}</p>
                <p class="text-two">
                  {{ 'affiliate_step1_text1' | translate }}
                  <a
                    [href]="affiliateRegisterLink"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="join-link mutual-opacity no-underline"
                    >{{ 'affiliate_step1_link' | translate }}</a
                  >
                  {{ 'affiliate_step1_text2' | translate }}
                </p>
                <p class="text-two">
                  {{ 'affiliate_step1_text3' | translate }}
                </p>
              </div>
            </div>
            <div class="step-two">
              <div class="top">
                <img src="assets/images/friend/application/calendar.svg" lazy="loading" />
                <img class="up" src="assets/images/friend/application/arrow-up.svg" lazy="loading" />
                <img class="bolang-h5" src="assets/images/friend/application/bolang.svg" lazy="loading" />
              </div>
              <div class="bottom">
                <p class="text-one">{{ 'affiliate_step2_title' | translate }}</p>
                <p class="text-two">{{ 'affiliate_step2_text' | translate }}</p>
              </div>
            </div>
            <div class="step-three">
              <p class="text-one">{{ 'affiliate_step3_title' | translate }}</p>
              <p class="text-one">$4,200 <span class="price">+14%</span></p>
              <img class="bar-chart" src="assets/images/friend/application/bar-chart.svg" lazy="loading" />
            </div>
            <div class="step-three-h5">
              <p class="text-one">{{ 'affiliate_step3_title' | translate }}</p>
              <div class="data-chart">
                <p class="text-one m-bottom-10">$4,200 <span class="price">+14%</span></p>
                <img class="bar-chart" src="assets/images/friend/application/bar-chart.svg" lazy="loading" />
              </div>
            </div>
          </div>
        </div>

        <div class="how-eligible">
          <div class="left-side">
            <p class="text-one">{{ 'affiliate_who' | translate }}</p>
            @for (li of whoTextList; track li.title) {
              <div class="text-list">
                <p>
                  <i class="icon-success"></i>
                  <span class="text-two">{{ li.title | translate }}</span>
                </p>
                @for (text of li.list; track text) {
                  <li class="text-three">{{ text | translate }}</li>
                }
              </div>
            }
          </div>
          <img
            class="brand"
            [src]="
              theme ? 'assets/images/friend/application/brand.png' : 'assets/images/friend/application/dark-brand.png'
            "
            lazy="loading"
            alt=""
          />
          <img class="bolang" src="assets/images/friend/application/bolang.svg" lazy="loading" />
          <div class="right-side">
            <p class="text-one">{{ 'affiliate_who' | translate }}</p>
            @for (li of whoTextList; track li.title) {
              <div class="text-list">
                <p>
                  <i class="icon-success"></i>
                  <span class="text-two">{{ li.title | translate }}</span>
                </p>
                @for (text of li.list; track text) {
                  <li class="text-three">{{ text | translate }}</li>
                }
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-container footer-background unity-padding-h5-16">
    <img class="footer-bg" src="assets/images/friend/application/footer-background.svg" alt="" lazy="loading" />
    <app-friend-footer
      [outerLink]="affiliateRegisterLink"
      [redirect]="onJoin()"
      [title]="footerData.title"
      [smallTitle]="footerData.smallTitle"
      [btnName]="footerData.btnName"
    >
    </app-friend-footer>
  </div>
</div>

<div class="page-container footer unity-padding-web unity-padding-h5-12">
  <div class="main-container">
    <div class="container footer-sign">
      <div class="content-box">
        <p>
          {{ disclaimer }}
        </p>
        <p>© 2006-{{ currentYear }} {{ 'brand_name' | translate }} | All Rights Reserved.</p>
      </div>
    </div>
    <div class="container license-box">
      <i class="icon-18plus"></i>
    </div>
    <div class="container game-18">
      <span>{{ 'g_c_b_a_p_r' | translate }}</span>
    </div>
  </div>
</div>
